var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chartElem"
  }, [_vm._m(0), _c('div', {
    attrs: {
      "id": "container"
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('highcharts', {
    staticClass: "chart",
    attrs: {
      "options": _vm.chartOptions
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "buttons"
  }, [_c('button', {
    attrs: {
      "id": "2000"
    }
  }, [_vm._v(" 2000 ")]), _c('button', {
    attrs: {
      "id": "2004"
    }
  }, [_vm._v(" 2004 ")]), _c('button', {
    attrs: {
      "id": "2008"
    }
  }, [_vm._v(" 2008 ")]), _c('button', {
    attrs: {
      "id": "2012"
    }
  }, [_vm._v(" 2012 ")]), _c('button', {
    staticClass: "active",
    attrs: {
      "id": "2016"
    }
  }, [_vm._v(" 2016 ")])]);
}]

export { render, staticRenderFns }